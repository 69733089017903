<template>
  <div style="background-color: #faf9f9">
    <Myhead :nav="4" :nav2="3"></Myhead>
    <FirstHeader :title="Language.dataDownload.Data_download" img-url="dataDownload" />
    <div class="item">
      <div>
        <a href="#dataDownload1" :class="downnav == 1 ? 'item-checked' :''" @click="changedownnav(1)">{{Language.dataDownload.standard_files}}</a>
        <a href="#dataDownload2" :class="downnav == 2 ? 'item-checked' :''" @click="changedownnav(2)">{{Language.dataDownload.APP}}</a>
<!--        <a href="#dataDownload3" :class="downnav == 3 ? 'item-checked' :''" @click="changedownnav(3)">{{Language.dataDownload.video}}</a>-->
      </div>
    </div>
    <div class="container">
      <!--标准文件-->
      <div class="file" id="dataDownload1">
        <div class="file-title">{{Language.dataDownload.standard_files}}</div>
        <div style="height: 550px;overflow: auto">
          <div class="file-item" v-for="(item, index) in fileList" :key="index">
            <div class="item-img">
              <img class="imgStyle" src="../assets/img/dataDownload/PDF.png" alt />
            </div>
            <div class="item-introduce">{{ item.EnName }}</div>
            <div class="btn-contactUs2" @click="toopen(item.file)">{{Language.dataDownload.Click_to_download}}</div>
          </div>
        </div>
      </div>
      <!--子牛APP-->
      <div class="app-download" id="dataDownload2">
        <div class="app-content">
          <div class="content-title">{{Language.dataDownload.APP}}</div>
          <div class="content-text">
            {{Language.dataDownload.message1}}
          </div>
          <div class="content-app">
            <div class="ios">
              <div class="ios-img">
                <img class="imgStyle" src="../assets/img/dataDownload/ios.jpg" alt />
              </div>
              <div class="btn-learnMore">IOS</div>
            </div>
            <div class="android">
              <div class="android-img">
                <img class="imgStyle" src="../assets/img/dataDownload/android.jpg" alt />
              </div>
              <div class="btn-learnMore">Android</div>
            </div>
          </div>
        </div>
        <div class="app-img">
          <img class="imgStyle" src="../assets/img/dataDownload/app.png" alt />
        </div>
      </div>
      <!--子牛操作视频-->
<!--      <div class="video" id="dataDownload3">-->
<!--        <div class="video-title">{{Language.dataDownload.video}}</div>-->
<!--        <div class="video-box">-->
<!--          <video controls loop src="../assets/img/dataDownload/test.mp4" />-->
<!--        </div>-->
<!--        <div class="btn-learnMore" @click="tocontactUs">{{Language.dataDownload.learn_More}}</div>-->
<!--      </div>-->
    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import FirstHeader from "@/component/firstHeader";
import Myfoot from "@/component/myfoot";
import Myhead from "@/component/myhead";
export default {
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components: {
    FirstHeader,
    Myfoot,
    Myhead
  },
  data () {
    return {
      fileList: [
        {
          introduce: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. '
        },
        {
          introduce: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. '
        },
        {
          introduce: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. '
        },
        {
          introduce: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. '
        }
      ],
      downnav:1
    }
  },
  activated() {

  },
  mounted() {
    this.getdata()
  },
  methods: {
    getdata(){
      // https://qua.link/api/study/datalist?pageNo=1
      this.$get('/api/study/datalist',{
        pageNo:-1
      }).then(res=>{
        console.log(res)
        this.fileList = res.data.data
      })
    },
    tocontactUs(){
      this.$router.push("/contact")
    },
    toopen(e){
      if(e){
        var path = e[0].AnnexDir
        var FileName = e[0].FileName
        path = path.replace(/\\/g, "/")
        window.open('http://file.qc-plus.cn:8090/' + path + FileName)
      }else {
        this.$toast("暂无pdf")
      }

    },
    changedownnav(e){
      this.downnav = e
    },
  }
}
</script>
<style lang="scss" scoped>
.btn-contactUs2{
  flex-shrink: 0;
}
.item {
  height: 80px;
  background-color: #FFFFFF;
  font-weight: bold;
  color: #000000;

  div {
    max-width: 1200px;
    height: 80px;
    line-height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    a{
      color: #000000;
    }
  }

  .item-checked {
    color: var(--theme-color1);
    font-weight: bold;
    border-bottom: 2px solid var(--theme-color1);
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;

  .file {
    margin-top: 80px;

    .file-title {
      font-size: 42px;
      color: var(--title-color);
      padding-bottom: 40px;
      border-bottom: 2px solid #e6e5e5;
    }

    .file-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;
      border-bottom: 1px solid #e6e5e5;

      .item-img {
        width: 72px;
        height: 72px;
        flex-shrink: 0;
      }

      .item-introduce {
        width: 890px;
        color: var(--text-color);
        line-height: 28px;
      }
    }
  }

  .app-download {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 160px 0 120px;
    border-bottom: 2px solid #e6e5e5;

    .app-content {
      flex: 1;

      .content-title {
        font-size: 42px;
        color: var(--title-color);
      }

      .content-text {
        color: var(--text-color);
        line-height: 28px;
        text-align: justify;
        letter-spacing: 1px;
        margin: 20px 0 44px;
      }

      .content-app {
        display: flex;
        align-items: center;
        justify-content: left;

        .btn-learnMore {
          width: 175px;
          margin-top: 16px;
        }

        .ios {
          margin-right: 30px;

          .ios-img {
            width: 175px;
            height: 175px;
          }
        }

        .android {

          .android-img {
            width: 175px;
            height: 175px;
          }
        }
      }
    }

    .app-img {
      width: 730px;
      height: 500px;
    }
  }

  .video {
    padding: 80px 0 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .video-title {
      color: var(--title-color);
      font-size: 42px;
      text-align: center;
      margin-bottom: 40px;
    }

    .video-box {
      width: 790px;
      height: 440px;
      border-radius: 24px;

      video {
        width: 790px;
        height: 440px;
        border-radius: 24px;
      }
    }

    .btn-learnMore {
      margin-top: 120px;
    }
  }
}

@media (min-width: 0px) and (max-width: 1200px){
  .btn-contactUs2{
    width: 130px;
  }
  .container .file{
    padding: 0px 20px ;
  }
  .container .app-download{
    padding:160px 20px 120px;
    flex-wrap: wrap;
    .content-app{
      justify-content: center !important;
    }
    .app-img{
      width: 100%;
      height: auto;
    }
  }
}
</style>
